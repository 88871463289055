import React from "react";
import Layout from "../components/layout";
import Activities from "../components/modules/TwoRowSection";
import Seo from "../components/seo";

const ActivitiesPage = () => {
  return (
    <div>
      <Layout>
        <Seo title="Actividades" />
        <Activities />
      </Layout>
    </div>
  );
};

export default ActivitiesPage;
