import { StaticImage } from "gatsby-plugin-image";
import React from "react";

import Surf1 from "../../../../assets/SurfVideo1.mp4";
import Surf2 from "../../../../assets/SurfVideo2.mp4";
import Surf3 from "../../../../assets/SurfVideo3.mp4";
import Surf4 from "../../../../assets/SurfVideo4.mp4";
import { ForecastWidgetDesktop } from "../../../atoms/ForecastWidget";

const Activities = () => {
  return (
    <div className="pb-8 lg:pb-0">
      <h2 className="title uppercase text-center pb-2">Actividades</h2>
      <div className="paragraph text-center space-y-4 pb-8 max-w-7xl px-2">
        <p className="uppercase font-bold text-center px-4 pt-2 lg:pt-0">
          NEXPA TIENE TODO LO QUE NECESITAS PARA UNAS INOLVIDABLES VACACIONES.
        </p>
        <p>
          Las playas de Nexpa son una de las mejores playas de la Costa del
          Pacífico Mexicano. Enamoran no solo a los surfistas que vienen de
          todas partes del mundo, sino también a aquellos que quieren relajarse
          y disfrutar de unos atardeceres increíbles.{" "}
        </p>
        <p>
          En Nexpa puedes pescar, o también rentar un kayak para navegar el río,
          surfear unas imponentes izquierdas, y por la noche, recargar energías
          con deliciosa comida, o hacer con amigos, una fogata y brindar con
          unas ricas cervezas frías.
        </p>
        <p>Te esperamos!</p>{" "}
      </div>
      <div className="flex overflow-x-scroll snap-x gap-4">
        <div className="snap-center flex-shrink-0">
          <StaticImage
            height={464}
            src="../../../images/Actividades/1.png"
            placeholder="tracedSVG"
            alt="entrance image"
          />
        </div>
        <div className="snap-center flex-shrink-0">
          <StaticImage
            height={464}
            src="../../../images/Actividades/2.png"
            placeholder="tracedSVG"
            alt="entrance image"
          />
        </div>
        <div className="snap-center flex-shrink-0">
          <StaticImage
            height={464}
            src="../../../images/Actividades/3.png"
            placeholder="tracedSVG"
            alt="entrance image"
          />
        </div>
        <div className="snap-center flex-shrink-0">
          <StaticImage
            height={464}
            src="../../../images/Actividades/4.png"
            placeholder="tracedSVG"
            alt="entrance image"
          />
        </div>
        <div className="snap-center flex-shrink-0">
          <StaticImage
            height={464}
            src="../../../images/Actividades/5.png"
            placeholder="tracedSVG"
            alt="entrance image"
          />
        </div>
        <div className="snap-center flex-shrink-0">
          <StaticImage
            height={464}
            src="../../../images/Actividades/6.png"
            placeholder="tracedSVG"
            alt="entrance image"
          />
        </div>
      </div>

      <h2 id="surf" className="title uppercase text-center pt-8 lg:pt-10">
        ¿Vienes a surfear?
      </h2>
      <div className="paragraph text-center space-y-4 pt-4 pb-8 max-w-7xl px-2">
        <p>
          La ola de Nexpa es conocida mundialmente por ser una ola izquierda de
          calidad consistente, poderosa y larga, que cuando conecta cada sección
          puede llegar a los 500 mts de largo. La ola point break de Nexpa es
          una muy versátil que puede ser buena con los principiantes y
          desafiante con los avanzados, para longboards y shortboards.{" "}
        </p>
        <p>
          Los swells más grandes puedes encontrarlos entre los meses de junio y
          agosto,en donde las olas suelen ponerse enromes!{" "}
        </p>
      </div>
      <div className="grid lg:grid-cols-2 mx-auto place-items-center gap-4">
        <div className="lg:ml-auto grid gap-4 px-2 lg:px-0">
          <video
            width={512}
            src={Surf1}
            muted
            controls
            type="video/mp4"
            title="video"
            className="shadow-sm mb-auto my-auto max-w-screen"
          />
          <video
            width={512}
            src={Surf2}
            muted
            controls
            type="video/mp4"
            title="video surf"
            className="shadow-sm mb-auto my-auto max-w-screen"
          />
        </div>
        <div className="hidden lg:grid lg:mr-auto gap-4">
          <video
            width={512}
            src={Surf3}
            muted
            controls
            type="video/mp4"
            title="video turn"
            className="shadow-sm mb-auto my-auto max-w-screen"
          />
          <video
            width={512}
            src={Surf4}
            muted
            controls
            type="video/mp4"
            title="video surf 2"
            className="shadow-sm mb-auto my-auto max-w-screen"
          />
        </div>
      </div>
      <div className="hidden lg:flex flex-col items-center mx-auto gap-4 py-8">
        <p className="paragraph text-center block pb-2">
          Checa aquí el estado de las olas antes de zambullirte al surf en
          Nexpa!
        </p>
        <div className="hidden lg:block">
          <ForecastWidgetDesktop />
        </div>
      </div>
    </div>
  );
};

export default Activities;
